
  var editorScriptEntry = { editorReady: function () {} };
  
  

  const initI18n = require('yoshi-flow-editor-runtime/build/cjs/i18next/init')['initI18n'];



  
  var editorReadyWrapper = require('yoshi-flow-editor-runtime/build/esm/editorScript.js').editorReadyWrapper;
  var onEventWrapper = require('yoshi-flow-editor-runtime/build/esm/editorScript.js').onEventWrapper;
  var editorReady = editorScriptEntry.editorReady;
  var onEvent = onEventWrapper({ onEvent: editorScriptEntry.onEvent });
  var sentryConfig = {
    DSN: 'https://ea002ae7678c4b8db9a0bb5b9175faaf@sentry.wixpress.com/1166',
    id: 'ea002ae7678c4b8db9a0bb5b9175faaf',
    projectName: 'social-groups-ooi',
    teamName: 'wix-groups',
  };
  var experimentsConfig = {"scopes":["specs.groups"]};
  var translationsConfig = {"defaultTranslationsPath":"/home/builduser/agent00/work/e95c89e2f004c2bf/social-groups/social-groups-ui/packages/social-groups-ooi/src/assets/locales/messages_en.json","icuEnabled":true};
  var defaultTranslations = {"app.widget.welcome":"Welcome","app.settings.label":"Configure Widget","app.settings.tabs.main":"Main","app.settings.tabs.design":"Design","app.settings.defaults.greetingsPretext":"to"};
  if (editorReady) {
    editorReady = editorReadyWrapper({
      editorReady: editorReady,
      sentryConfig: sentryConfig,
      experimentsConfig: experimentsConfig,
      translationsConfig: translationsConfig,
      defaultTranslations: defaultTranslations,
      artifactId: 'social-groups-ooi',
      optionalDeps: {
        initI18n,
        
      },
      localeDistPath: 'assets/locales',
    });
  }
  module.exports = editorScriptEntry.default || {
    ...editorScriptEntry,
    onEvent,
    editorReady,
  };
  
